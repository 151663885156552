import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';
import i18next from './i18next';
import { useTranslation } from 'react-i18next';

const MyAccount = ({ isLoading, setIsLoading, dragIcon, eyeIcon, globeIcon }) => {
  const [followers, setFollowers] = useState([]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isPublic, setIsPublic] = useState(null); // Ustawienie początkowego stanu na wartość user.isPublic
  const [followingUsers, setFollowingUsers] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'pl');
  const [followingStates, setFollowingStates] = useState({}); // Obiekt do przechowywania stanów

  const handleNavigation = (path) => {
    window.location.href = path; // wymuś przejście na nowy adres
  };

  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
    setSelectedLanguage(lng);
    localStorage.setItem('language', lng);
  };

    
      useEffect(() => {
            // Pobierz dane użytkownika z localStorage, jeśli są dostępne
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
        const fetchUserData = async () => {
          setIsLoading(true);

          try {
            setIsLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
    
            const userData = response.data;
            setUser(userData);
            localStorage.setItem('user', JSON.stringify(userData));
    
            setIsPublic(userData.isPublic);
          } catch (error) {
            console.error('Error fetching user data:', error);
          } finally {
            setIsLoading(false);
          }
        };
    
        fetchUserData();
      }, [setIsLoading]);
    



    // Pobieranie listy osób, które śledzą bieżącego użytkownika
  useEffect(() => {
    const fetchFollowers = async () => {
      setIsLoading(true);

      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/followers`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setFollowers(response.data);  // Ustawienie stanu followers
      } catch (error) {
        console.error('Error fetching followers:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFollowers();
  }, [setIsLoading]);  // Użycie pustej tablicy [] oznacza, że efekt zostanie wykonany tylko raz po załadowaniu komponentu


  

  useEffect(() => {
    const fetchFollowingUsers = async () => {
      setIsLoading(true);

      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/following`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setFollowingUsers(response.data);
  
        // Inicjalizacja domyślnego stanu `isFollowing` na `true` dla każdego użytkownika
        const initialFollowingState = response.data.reduce((acc, user) => {
          acc[user.googleId] = { isFollowing: true, loading: false }; // Zawsze `true` na start
          return acc;
        }, {});
  
        setFollowingStates(initialFollowingState);
      } catch (error) {
        console.error('Error fetching following users:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchFollowingUsers();
  }, [setIsLoading]);
  


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/signup');
      return;
    }
  }, [navigate]);

  // Zmiana statusu profilu publiczny/prywatny
  const handlePrivacyChange = async (event) => {
    const newPrivacyStatus = event.target.checked;
    setIsPublic(newPrivacyStatus); // Aktualizacja lokalnego stanu

    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/privacy`, {
        isPublic: newPrivacyStatus,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    } catch (error) {
      console.error('Error updating privacy status:', error);
    }
  };

  
  const handleFollow = async (googleId) => {
    setFollowingStates(prevStates => ({
      ...prevStates,
      [googleId]: { ...prevStates[googleId], loading: true }
    })); // Ustaw spinner dla konkretnego użytkownika
  
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/follow`,
        { followUserId: googleId },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      
      setFollowingStates(prevStates => ({
        ...prevStates,
        [googleId]: { isFollowing: true, loading: false }
      })); // Zaktualizuj stan dla konkretnego użytkownika
    } catch (error) {
      console.error('Error following user:', error.response ? error.response.data : error.message);
      setFollowingStates(prevStates => ({
        ...prevStates,
        [googleId]: { ...prevStates[googleId], loading: false }
      })); // Wyłącz spinner w przypadku błędu
    }
  };
  
  const handleUnfollow = async (googleId) => {
    setFollowingStates(prevStates => ({
      ...prevStates,
      [googleId]: { ...prevStates[googleId], loading: true }
    })); // Ustaw spinner dla konkretnego użytkownika
  
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/unfollow`,
        { unfollowGoogleId: googleId },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      
      setFollowingStates(prevStates => ({
        ...prevStates,
        [googleId]: { isFollowing: false, loading: false }
      })); // Zaktualizuj stan dla konkretnego użytkownika
    } catch (error) {
      console.error('Error unfollowing user:', error.response ? error.response.data : error.message);
      setFollowingStates(prevStates => ({
        ...prevStates,
        [googleId]: { ...prevStates[googleId], loading: false }
      })); // Wyłącz spinner w przypadku błędu
    }
  };

  if (isLoading) {
    return <div className="loader-container"><div className="loader"></div></div>;
  }

  // Renderowanie komponentu
  return (
    
    <Container fluid className="px-3 main-container my-account">
      <Row className="mb-4 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
        <h1 className="page-title">{t('my_account.title')}</h1>

<div className="account-intro">
          <img
            src={user?.avatarUrl && user.avatarUrl.includes('http')
              ? user.avatarUrl
              : `${process.env.REACT_APP_API_BASE_URL}/img${user?.avatarUrl || ''}`}
            alt="User Avatar"
            className="user-avatar"
          />
            <h2 className="mb-1 mt-3">{user && user.name ? user.name : ''}</h2>

          
<p>{user && typeof isPublic === 'boolean' ? (isPublic ? t('my_account.public') : t('my_account.private')) : ''}</p>
</div>

        </Col>
      </Row>

      {/* Dodajemy selektor języka */}
      <Row className="g-0 mb-5 justify-content-center">
      <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
      <h2 className="section-title mb-1">{t('my_account.settings')}</h2>

      <div className="info-table">
        
          <div className="info-row">
            <div className="info-icon">
              <img src={globeIcon} alt="Icon" />
            </div>
            <div className="info-name">{t('my_account.select_language')}</div>
            <div className="info-content">
            <Form.Group controlId="languageSelect">
  <Form.Check 
    type="radio" 
    id="radio-en"  // Unikalny ID dla English
    label="English" 
    value="en" 
    checked={selectedLanguage === 'en'} 
    onChange={(e) => changeLanguage(e.target.value)}
  />
  <Form.Check 
    type="radio" 
    id="radio-pl"  // Unikalny ID dla Polski
    label="Polski" 
    value="pl" 
    checked={selectedLanguage === 'pl'} 
    onChange={(e) => changeLanguage(e.target.value)}
  />
</Form.Group>


            </div>
          </div>
        

          {isPublic !== null && ( // Renderujemy przełącznik tylko, jeśli isPublic nie jest null

          <div className="info-row">
            <div className="info-icon">
              <img src={eyeIcon} alt="Icon" />
            </div>
            <div className="info-name">{t('my_account.privacy')}</div>
            <div className="info-content">
     
            <Form>
  <Form.Group>
    <Form.Check
      type="switch"
      id="public-private-switch"
      label={isPublic ? t('my_account.public') : t('my_account.private')}
      checked={isPublic}
      onChange={handlePrivacyChange}
    />
    <p className="text-muted">
      {isPublic ? t('my_account.public_subtitle') : t('my_account.private_subtitle')}
    </p>
  </Form.Group>
</Form>


   

            </div>
          </div>
           )}
      </div>
</Col>

</Row>
      <Row className="mb-5 justify-content-center users-row">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
        <h2 className="section-title mb-1">{t('my_account.following')}</h2>

        {followingUsers.map(user => (
  <Card key={user.googleId}>
<Card.Body className="users-list justify-content-between align-items-center">
  <div className="users-list-main d-flex justify-content-between align-items-center">
    <Link to={`/user/${user.googleId}`} className="user-link">
      <div className="users-list-left align-items-center">
        <img src={user.avatarUrl} alt={user.name} className="user-avatar" />
        <p className="user-name">
          {user.name}
        </p>
      </div>
    </Link>
    <div className="div-unfollow">
      {followingStates[user.googleId]?.isFollowing ? (
        <Button 
          variant="danger" 
          className="btn-smallest"
          onClick={() => handleUnfollow(user.googleId)} 
          disabled={followingStates[user.googleId]?.loading}
        >
          {followingStates[user.googleId]?.loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            t('my_account.unfollow')
          )}
        </Button>
      ) : (
        <Button 
          variant="success" 
          className="btn-smallest"
          onClick={() => handleFollow(user.googleId)} 
          disabled={followingStates[user.googleId]?.loading}
        >
          {followingStates[user.googleId]?.loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            t('my_account.follow')
          )}
        </Button>
      )}
    </div>
  </div>
</Card.Body>
  </Card>
))}

        </Col>
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
        <h2 className="section-title mt-4 mb-1">{t('my_account.followers')}</h2>


              
        {followers.map(user => (
  <Card key={user.googleId}>
    <Card.Body className="users-list justify-content-between align-items-center">
      <div className="users-list-main d-flex justify-content-between align-items-center">
        <Link to={`/user/${user.googleId}`} className="user-link">
        <div className="users-list-left align-items-center">
          <img src={user.avatarUrl} alt={user.name} className="user-avatar" />
          <p className="user-name">
            {user.name}
          </p>
          </div>
        </Link>
      </div>
    </Card.Body>
  </Card>
))}
              
        </Col>
      </Row>

      <Row className="mb-5 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
        <Button 
      variant="primary" 
      className="see-rank-button" 
      onClick={() => handleNavigation('/stats-rank')}
    >
      {t('my_account.stats_rank')}
    </Button>
        </Col>
        </Row>

    </Container>
  );
};

export default MyAccount;
