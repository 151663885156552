import React, { useState, useEffect } from 'react';
import { Modal, Button, FormControl, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


function ShowModal({ 
    show, onClose, colorCircleCalendarIcon, colorCircleStarIcon, starIcon,
    getRatingDescription, successIcon, t, currentLocale,  selectedEntry,
}) {

  const [userRating, setUserRating] = useState(0);
  const [tempRating, setTempRating] = useState(0); // Tymczasowa ocena podczas edycji
  const [hoverRating, setHoverRating] = useState(null); // Rating przy najechaniu myszą
  const [ratingDescription, setRatingDescription] = useState(''); // Opis oceny
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isRemoved, setIsRemoved] = useState(false);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false); 
  const [dateWatched, setDateWatched] = useState(new Date());
  const [pendingDateWatched, setPendingDateWatched] = useState(new Date());
  const [manualYear, setManualYear] = useState(new Date().getFullYear()); // Rok wprowadzony ręcznie
  const [isLoading, setIsLoading] = useState(false); // Stan dla loadera
const [isSuccess, setIsSuccess] = useState(false); // Stan dla statusu sukcesu
  


useEffect(() => {
  if (selectedEntry) { // Ensure selectedEntry is defined
    setUserRating(selectedEntry.userRating || 0);
    setTempRating(selectedEntry.userRating || 0);
      setRatingDescription(getRatingDescription(selectedEntry.userRating || 0));

      // Ustaw aktualną datę obejrzenia spektaklu
      const watchedDate = selectedEntry?.dateWatched ? new Date(selectedEntry.dateWatched) : new Date();
      setDateWatched(watchedDate);
      setPendingDateWatched(watchedDate);
      setManualYear(watchedDate.getFullYear()); // Ustawienie roku wprowadzanego ręcznie na podstawie aktualnej daty

    }
  }, [selectedEntry, getRatingDescription]);



// W funkcji handleRatingChange
const handleRatingChange = (newRating) => {
  setTempRating(newRating); // Zapisz tymczasową ocenę
  setRatingDescription(getRatingDescription(newRating)); // Aktualizuj opis oceny
};

  const handleMouseEnter = (index) => {
    setHoverRating(index);
  };

  const handleMouseLeave = () => {
    setHoverRating(null);
  };

// W funkcji saveRating
// W funkcji saveRating
const saveRatingAndDate = async () => {
  setIsLoading(true); // Włącz loader
  setIsSuccess(false); // Zresetuj status sukcesu
  try {
    await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/diary/update`, {
      diaryEntryId: show._id, // Przekaż unikalny ID wpisu
      rating: tempRating || 0,  // Domyślna wartość 0, jeśli tempRating jest null
      dateWatched: dateWatched.toISOString() // Data w formacie ISO
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    setUserRating(tempRating);
    setConfirmationMessage(t('modals.show_modal.rating_date_successful'));
    setIsSuccess(true); // Wyświetl status sukcesu
  } catch (error) {
    console.error('Error saving rating and date:', error.response ? error.response.data : error.message);
  } finally {
    setIsLoading(false); // Wyłącz loader po zakończeniu operacji
  }
};
  

  const renderStarRating = (rating) => {
    const stars = [];
    for (let i = 1; i <= 10; i++) {
      stars.push(
        <span
          key={i}
          onClick={() => handleRatingChange(i)}
          onMouseEnter={() => handleMouseEnter(i)}
          onMouseLeave={handleMouseLeave}
          className={'star-icon'}
          style={{ color: i <= (hoverRating ?? tempRating) ? '#FFC319' : '#ccc', cursor: 'pointer' }}
        >
          ★
        </span>
      );
    }
    return stars;
  };

  const handleRemoveFromDiary = async (show, dateWatched) => {
    setIsLoading(true); // Włącz loader
    setIsSuccess(false); // Zresetuj status sukcesu
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/diary/remove`, {
        showId: show.show._id,
        dateWatched: show.dateWatched,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      setConfirmationMessage(t('modals.show_modal.remove_successful'));
      setIsRemoved(true);
    } catch (error) {
      console.error('Error removing show entry from diary:', error.response ? error.response.data : error.message);
    } finally {
      setIsLoading(false); // Wyłącz loader po zakończeniu operacji
    }
  };
  
  const handleOpenDateModal = () => {
    setIsDateModalOpen(true);
  };

  const handleCloseDateModal = () => {
    setIsDateModalOpen(false);
  };

  const handleDateChange = (date) => {
    setPendingDateWatched(date);
    setManualYear(date.getFullYear()); // Aktualizacja pola z rokiem przy wyborze daty z kalendarza

  };

  const handleYearChange = (e) => {
    const year = parseInt(e.target.value, 10);
    if (!isNaN(year)) {
      setManualYear(year);
      const updatedDate = new Date(pendingDateWatched);
      updatedDate.setFullYear(year); // Zmieniamy rok w aktualnie wybranej dacie
      setPendingDateWatched(updatedDate); // Aktualizujemy datę w kalendarzu
    }
  };

  const handleSaveDateChange = () => {
    setDateWatched(pendingDateWatched);
    setIsDateModalOpen(false);
  };

const handleSaveAndClose = async () => {
  console.log('Saving and closing. Current tempRating:', tempRating, 'and dateWatched:', dateWatched);
  await saveRatingAndDate();

};

  const handleCloseAndReload = () => {
    onClose();
    window.location.reload();
  };

  useEffect(() => {
    if (!isDateModalOpen) {
      setConfirmationMessage('');
    }
  }, [isDateModalOpen]);

  const handleModalClose = () => {
    console.log('Modal is being closed');

    // Zerowanie tymczasowej oceny po zamknięciu modala
    setTempRating(userRating);
    setHoverRating(null);
    onClose();

  };

  if (!show) return null;

  return (
    <>
      <Modal show={!!show} onHide={handleModalClose} centered backdrop="true">
  <Modal.Header closeButton className="modal-header"></Modal.Header>
  <Modal.Body>
    {isLoading ? (
      <div className="loader-container"><div className="loader"></div></div> 
    ) : isSuccess ? (
      <div className="confirmation-message-modal-rem">
        <div className="confirmation-message">
          <img src={successIcon} className="success-icon" alt="Success" />
          <p>{confirmationMessage}</p>
        </div>
        <Button variant="primary" onClick={handleCloseAndReload}>
          {t('modals.show_modal.close_button')}
        </Button>
      </div>
    ) : isRemoved ? (
      <div className="confirmation-message-modal-rem">
        <div className="confirmation-message">
          <img src={successIcon} className="success-icon" alt="Success" />
          <p>{confirmationMessage}</p>
        </div>
        <Button variant="primary" onClick={handleCloseAndReload}>
          {t('modals.show_modal.close_button')}
        </Button>
      </div>
    ) : (
<div className="modal-section">
  <div className="show-card">
    <div className="show-poster">
      {show.show.isVerified ? (
        <Link to={`/shows/${show.show.slug}`} className="show-link">
          <img 
            src={
              show.show.poster.includes('http')
                ? show.show.poster
                : `${process.env.REACT_APP_API_BASE_URL}/img${show.show.poster}`
            } 
            alt={show.show.title} 
            className="modal-poster" 
          />
        </Link>
      ) : (
        <img 
          src={
            show.show.poster.includes('http')
              ? show.show.poster
              : `${process.env.REACT_APP_API_BASE_URL}/img${show.show.poster}`
          } 
          alt={show.show.title} 
          className="modal-poster" 
        />
      )}
                </div>
              </div>
              <Link to={`/shows/${show.slug}`} className="show-link">
                <h5 className="show-title">{show.show.title}</h5>
              </Link>
              <div className="modal-subsection">
                <div className="modal-small-section">
                  <div className="modal-small-section-top">
                    <div className="icon-section">
                      <img src={colorCircleCalendarIcon} alt="Calendar Icon" />
                    </div>
                    <div className="content">
                      <h6><strong>{t('modals.show_modal.date_of_show')}</strong></h6>
                      <p className="sub">{dateWatched ? dateWatched.toLocaleDateString() : 'No date available'}</p>
                    </div>
                  </div>
                  <div className="modal-small-section-bottom">
                    <Button variant="light" className="btn-small" onClick={handleOpenDateModal}>{t('modals.show_modal.change_date_button')}</Button>
                  </div>
                </div>
                <div className="modal-small-section">
                  <div className="modal-small-section-top">
                    <div className="icon-section">
                      <img src={colorCircleStarIcon} alt="Star Icon" />
                    </div>
                    <div className="content">
                      <strong>{t('modals.show_modal.rating_label')}: </strong>
                      <p className="rating-summary-p">
                    {t('modals.add_show_modal.your_rating')}:&nbsp;
                    <strong>{tempRating ? `${ratingDescription} (${tempRating})` : t('modals.add_show_modal.no_rating')}</strong>
                  </p>
                      </div>
                  </div>
                  <div className="modal-small-section-bottom">
                    <div className="star-rating">
                    {renderStarRating(tempRating)}
                    </div>
                  </div>
                </div>
              </div>
              </div>
    )}
  </Modal.Body>
  {!isRemoved && !isLoading && !isSuccess && (
    <Modal.Footer className="modal-footer">
      <Button variant="primary" onClick={handleSaveAndClose}>
        {t('modals.show_modal.save_button')}
      </Button>
      <Button variant="light" onClick={handleModalClose}>
        {t('modals.show_modal.cancel_button')}
      </Button>
      <Button variant="danger" onClick={() => handleRemoveFromDiary(show, show.dateWatched)}>
        {t('modals.show_modal.remove_button')}
      </Button>
    </Modal.Footer>
  )}
</Modal>

            {/* Nowy modal z kalendarzem */}
            <Modal show={isDateModalOpen} onHide={handleCloseDateModal} className="modal-calendar-container" centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="custom-datepicker">
          <div className="modal-calendar">
          <InputGroup className="mb-3">
              <InputGroup.Text>{t('modals.show_modal.year_label')}</InputGroup.Text>
              <FormControl
                type="number"
                value={manualYear}
                onChange={handleYearChange}
                aria-label="Year"
              />
            </InputGroup>
          <DatePicker 
                        selected={pendingDateWatched}
                        onChange={handleDateChange}
                        inline
                        calendarStartDay={1} // Ustawienie pierwszego dnia tygodnia na poniedziałek
                        locale={currentLocale} // Ustawiamy lokalizację kalendarza

                    />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSaveDateChange}>{t('modals.show_modal.save_button')}</Button>
          <Button variant="light" onClick={handleCloseDateModal}>{t('modals.show_modal.cancel_button')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShowModal;
