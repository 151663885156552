// Importowanie zależności
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { Container, Row, Col, Card, Button, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import './SkeletonLoader.css'; // Dodaj własne style


// Importowanie stylów i ikon
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css';

// Importowanie komponentów niestandardowych
import SearchModal from './SearchModal';
import ShowModal from './ShowModal';

// Komponent Homepage
function Homepage({ 
    user,
    setUser,
    searchIcon, 
    rankIcon, 
    starIcon, 
    calendarIcon,
    bigFeaturedIcon,
    bigRankIcon,
    bigRankAllIcon,
    bigDiaryIcon,
    colorCircleCalendarIcon,
    colorCircleStarIcon,
    getRatingDescription,
    renderStarRating,
    plusIcon,
    infoIcon,
    successIcon,
    POLAND_CITIES,
    FEATURED_SHOWS,
    arrowLeftIcon,
    arrowRightIcon,
    defaultPoster,
    t,
    currentLocale,
    formatDate,
    userIcon,
    heartIcon
  }) {

  // Deklaracja zmiennych stanu
  const [shows, setShows] = useState([]);
  const [userDiary, setUserDiary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(POLAND_CITIES);
  const [activeMyShowsList, setActiveMyShowsList] = useState('Next');
  const [wishListShows, setWishListShows] = useState([]);
  const [selectedShow, setSelectedShow] = useState(null); // Dodaj stan wybranego spektaklu dla modalu
  const [loadingButtons, setLoadingButtons] = useState({}); // Stan dla śledzenia ładowania przycisków
  const [selectedEntry, setSelectedEntry] = useState(null); // <-- Add this line



  const SkeletonLoaderFeatured = ({ width, height }) => {
    return (
      <div className="show-card-wrapper">
        <div className="skeleton-card is-loading featured-show-card">
          <div className="show-image-layer">
          </div>
          <div className="show-info-layer">
            <div className="top-layer">
              <div className="show-details">
                <div className="rating-wrapper">
                  <p className="average-rating"></p>
                </div>
              </div>
            </div>
            <p className="show-description"></p>
          </div>
        </div>
      </div>
    );
  };

  const SkeletonLoaderMyShows = ({ width, height }) => {
    return (
      <div className="skeleton-card is-loading show-card">
        <div className="show-poster">
        </div>

        <div className="show-info">
          <p className="show-title"></p>
          <p className="show-date"></p>
        </div>
      </div>
    );
  };

  const SkeletonLoaderBest = ({ width, height }) => {
    return (
      <div className="skeleton-card is-loading show-card">

        <div className="show-poster">
          <div className="poster-img"></div>
        </div>

        <div className="show-info">
          <p className="ranking"></p>

          <div className="show-info-basic">
          </div>

          <p></p>
          <p></p>
          </div>


        
      </div>
    );
  };

  const navigate = useNavigate(); // Dodaj hook nawigacji

  // Śledzenie przesunięcia myszy
  const [isDragging, setIsDragging] = useState(false);
  
  const today = new Date().setHours(0, 0, 0, 0);

  // Filtrowanie spektakli użytkownika na podstawie daty oglądania
// Filtrowanie i sortowanie spektakli użytkownika na podstawie daty oglądania
const nextMyShows = userDiary
  .filter(entry => new Date(entry.dateWatched).setHours(0, 0, 0, 0) >= today)
  .sort((a, b) => new Date(a.dateWatched) - new Date(b.dateWatched)); // Sortowanie od najwcześniejszych do najpóźniejszych

const seenMyShows = userDiary
  .filter(entry => new Date(entry.dateWatched).setHours(0, 0, 0, 0) < today)
  .sort((a, b) => new Date(b.dateWatched) - new Date(a.dateWatched)); // Sortowanie od najpóźniejszych do najwcześniejszych

  const wishListMyShows = []; // Testowe dane na przyszłe potrzeby

// Przycięcie list Next i Seen do 9 elementów
const displayedNextMyShows = nextMyShows.slice(0, 9);
const displayedSeenMyShows = seenMyShows.slice(0, 9);

// Wyświetlanie listy aktywnych spektakli z ograniczeniem do 9
const activeMyShows = activeMyShowsList === 'Next' ? displayedNextMyShows : activeMyShowsList === 'Seen' ? displayedSeenMyShows : wishListMyShows;

// Stan dla paginacji
const [wishListPage, setWishListPage] = useState(1);
const itemsPerPage = 9;

// Funkcja do ładowania kolejnych elementów Wish-list
const handleShowMoreWishList = () => {
  setWishListPage(prevPage => prevPage + 1);
};

// Obliczenie elementów do wyświetlenia
const displayedWishListShows = wishListShows.slice(0, wishListPage * itemsPerPage);

// Pobieranie listy życzeń użytkownika
useEffect(() => {
  if (user) {
    const fetchWishList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/wishlist`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });

        setWishListShows(response.data); // Zaktualizuj listę życzeń
      } catch (err) {
        console.error('Error fetching wish list:', err);
      }
    };

    fetchWishList();
  }
}, [user]);





const removeFromWishList = async (showId) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/wishlist/remove`, { showId }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    // Znajdź i ustaw flagę `isRemoved` na true dla danego spektaklu
    setWishListShows(
      wishListShows.map(show =>
        show._id === showId ? { ...show, isRemoved: true } : show
      )
    );
  } catch (err) {
    console.error('Error removing from wish list:', err);
  }
};

const addAgainToWishList = async (showId) => {
  setLoadingButtons(prevState => ({ ...prevState, [showId]: true })); // Ustaw spinner dla konkretnego show
  try {
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/wishlist/add`, { showId }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    // Zresetuj flagę `isRemoved` dla danego spektaklu
    setWishListShows(
      wishListShows.map(show =>
        show._id === showId ? { ...show, isRemoved: false } : show
      )
    );
  } catch (err) {
    console.error('Error adding to wish list again:', err);
  } finally {
    setLoadingButtons(prevState => ({ ...prevState, [showId]: false })); // Wyłącz spinner
  }
};


// Obsługa wylogowania użytkownika, zapamiętana przez useCallback
const handleLogout = useCallback(() => {
  setUser(null);
  localStorage.removeItem('user');
  localStorage.removeItem('token');
}, [setUser]); // Zależność: setUser
  

  // Pobieranie danych o spektaklach z API przy pierwszym załadowaniu komponentu
  useEffect(() => {
    async function fetchShows() {
      try {
        setIsLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/shows`, {
          method: 'GET',
          credentials: 'include', // Ważne, jeśli używasz ciasteczek
          headers: {
            'Content-Type': 'application/json',
          }
        });
        const data = await response.json();
        setShows(data);
      } catch (error) {
        console.error('Error fetching shows', error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchShows();
  }, []);
  


  // Pobieranie danych o spektaklach i dzienniku użytkownika
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [showsResponse, diaryResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/shows`),
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/diary`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }),
        ]);
        setShows(showsResponse.data);
        setUserDiary(diaryResponse.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleLogout(); // Force logout on token expiration
        }
        console.error('Error fetching data:', error);
      }
    };
    
    fetchData();
  }, [handleLogout]); // Dodaj handleLogout jako zależność
  

// Pobranie oceny użytkownika dla konkretnego spektaklu
const getUserRatingForShow = (showId) => {
  // Filtrowanie wpisów dla konkretnego showId
  const entries = userDiary.filter(entry => entry.show._id === showId);
  
  if (entries.length === 0) {
    return null;
  }

  // Filtrowanie ocen, aby uwzględnić tylko te, które nie są null
  const ratings = entries
  .map(entry => entry.userRating)
  .filter(rating => rating !== null && rating !== 0); // Dodano warunek, aby wykluczyć oceny 0


  if (ratings.length === 0) {
    return null;
  }

  // Sumowanie ocen i obliczanie średniej
  const totalRating = ratings.reduce((sum, rating) => sum + rating, 0);
  const averageRating = totalRating / ratings.length;

  return averageRating.toFixed(1); // Zaokrąglenie do jednego miejsca po przecinku
};
  
  
// Funkcja do zmiany lokalizacji spektakli
const handleChangeLocation = (locations) => {
  setCurrentLocation(locations);
};


// Filtrowanie spektakli na podstawie aktualnej lokalizacji
const showsByLocation = shows.filter(show =>
  currentLocation.some(location =>
    show.location 
    ? show.location.split(',').map(city => city.trim()).includes(location)
    : false // Sprawdzenie czy show.location istnieje przed użyciem split()
  )
);




    // Funkcja do obsługi nawigacji po kliknięciu linku (bez przeciągania)
    const handleClick = (e, slug) => {
      if (isDragging) {
        e.preventDefault();
      } else {
        navigate(`/shows/${slug}`);
      }
    };
    
// Komponent strzałki w lewo
const PrevArrow = (props) => {
  const { className, onClick, style } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
      }}
    >
      <img src={arrowLeftIcon} alt="Previous" className="slider-custom-icon" />
    </div>
  );
};

// Komponent strzałki w prawo
const NextArrow = (props) => {
  const { className, onClick, style } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
      }}
    >
      <img src={arrowRightIcon} alt="Next" className="slider-custom-icon" />
    </div>
  );
};

  

// Ustawienia dla karuzeli (Slider)
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  variableWidth: true,
  autoplay: true,
  autoplaySpeed: 3000,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  cssEase: "cubic-bezier(0.25, 0.1, 0.25, 1)", // Dodaje płynniejszą animację
  touchThreshold: 5, // Zmniejsza próg dotknięcia dla łatwiejszego przewijania
  useCSS: true, // Używa CSS transitions dla animacji
  useTransform: true, // Używa CSS3 transforms dla animacji
  waitForAnimate: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        swipe: true,
        swipeToSlide: true,
        touchMove: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        swipe: true,
        swipeToSlide: true,
        touchMove: true,
      },
    },
  ],
};


const featuredShows = shows
  .filter(show => FEATURED_SHOWS.includes(show.slug))
  .sort((a, b) => FEATURED_SHOWS.indexOf(a.slug) - FEATURED_SHOWS.indexOf(b.slug));


  const startDate = new Date('2024-09-01');
  const endDate = new Date('2025-06-30');
  
  const thisSeasonShows = shows
    .filter(show => {
      // Sprawdzamy, czy show.premiere istnieje i czy jest prawidłową datą
      if (!show.premiere) return false;
      const premiereDate = new Date(show.premiere);
      return !isNaN(premiereDate) && premiereDate >= startDate && premiereDate <= endDate;
    })
    .sort((a, b) => new Date(a.premiere) - new Date(b.premiere));  // Sortowanie według daty premiery




  const predefinedLocations = [
    { key: 'POLAND', label: t('best-played.countries.Poland'), locations: POLAND_CITIES },
    { key: 'NYC', label: t('best-played.locations.NYC'), locations: ['New York'] },
    { key: 'WEST_END', label: t('best-played.locations.West-End'), locations: ['London'] },
    { key: 'PL-WARSAW', label: t('best-played.locations.PL-Warsaw'), locations: ['Warszawa'] },
    { key: 'PL-LODZ', label: 'Łódź', locations: ['Łódź'] },
    { key: 'PL-POZNAN', label: 'Poznań', locations: ['Poznań'] },
    { key: 'PL-GDYNIA', label: 'Gdynia', locations: ['Gdynia'] },
    { key: 'PL-KRAKOW', label: 'Kraków', locations: ['Kraków'] },
    { key: 'PL-WROCLAW', label: 'Wrocław', locations: ['Wrocław'] },
    { key: 'PL-CHORZOW', label: 'Chorzów', locations: ['Chorzów'] },
    // Dodaj więcej lokalizacji w razie potrzeby
  ];
  

  // Sortowanie spektakli według avgRating
  const sortedShowsByLocation = showsByLocation
  .filter(show => show.globalRank !== null && show.globalRank > 0) // Dodanie filtra globalRank
  .sort((a, b) => b.avgRating - a.avgRating) // Sortowanie według średniej oceny malejąco
  .slice(0, 6); // Obcinanie listy do maksymalnie 6 elementów

// Dynamiczne określanie pozycji w rankingu
const showsWithDynamicRank = sortedShowsByLocation.map((show, index) => ({
  ...show,
  dynamicRank: index + 1,
}));



  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const openSearchModal = () => {
    setIsSearchModalOpen(true);
  };

  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };

// Użyj tego:
const handleShowClick = (entry) => {
  setSelectedShow(entry);
  setSelectedEntry(entry); // <-- Set selectedEntry instead of selectedShow

};
    // Funkcja obsługująca zamykanie modalu
    const handleCloseModal = () => {
      setSelectedShow(null);
      setSelectedEntry(null); // Reset selectedEntry when modal closes

    };

    const handleNavigation = (path) => {
      window.location.href = path; // wymuś przejście na nowy adres
    };
    


    

  return (
    <Container fluid className="px-3 main-container">
      
      {/* Sekcja - Powitanie i wyszukiwanie */}
      <Row className="mb-5 greeting-section justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="d-flex align-items-center resp-pre-xl justify-content-between">
        {user && user.name ? (
  <>
    <div className="greeting-text-container d-flex">
      <h1 className="header-title">{t('greeting.hello')}, {user.name.split(' ')[0]}!</h1>
      <p className="header-subtitle">{t('greeting.theatre_daily')}</p>
    </div>
    <div className="user-panel">
      <Link to="/stats-rank">
        <img 
          src={user?.avatarUrl && user.avatarUrl.includes('http') 
            ? user.avatarUrl 
            : `${process.env.REACT_APP_API_BASE_URL}/img${user?.avatarUrl || '/default-avatar.png'}`} 
          alt="User Avatar" 
          className="user-avatar" 
        />
      </Link>
    </div>
  </>
) : (
  <div className="login-section">
    <h1 className="header-title">{t('login.welcome')}</h1>
    <p className="header-subtitle">{t('login.log_in_prompt')}</p>
    <Link to="/signup">
      <Button variant="primary" className="quick-button">{t('login.signup_button')}</Button>
    </Link>
  </div>
)}
        </Col>
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="mt-3 resp-pre-xl">
        <InputGroup className="search-input-group" onClick={openSearchModal} >
          <FormControl
            placeholder={t('search.placeholder')}  // Użycie tłumaczenia dla placeholdera
            aria-label="Search"
            style={{ paddingLeft: '40px' }} // Odstęp dla ikony
          />
          <img src={searchIcon} alt="Search" className="search-icon" />
        </InputGroup>

      <SearchModal
        isOpen={isSearchModalOpen}
        onRequestClose={closeSearchModal}
        starIcon={starIcon}
        searchIcon={searchIcon}
        currentLocale={currentLocale}
        defaultPoster={defaultPoster}
        colorCircleCalendarIcon={colorCircleCalendarIcon}
        colorCircleStarIcon={colorCircleStarIcon}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        successIcon={successIcon}

getRatingDescription={getRatingDescription}

      />
        </Col>
      </Row>

     {/* Sekcja - Wyróżnione Spektakle */}
     <Row className="g-0 my-4 mb-5 featured-shows justify-content-center">
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center resp-pre-xl px12">
  <h2 className="section-title mb-1">{t('featured_shows.title')}</h2>
  </Col>
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl px12"> 
    <Slider className="mb-0" {...settings}>
    {isLoading ? (
        // Wyświetlaj skeleton loader gdy dane są ładowane
        Array.from({ length: 3 }).map((_, index) => (
          <SkeletonLoaderFeatured key={index}  />
        ))
      ) : (
        // Wyświetl rzeczywiste dane po ich załadowaniu
        featuredShows.map((show, index) => (
        <div key={index} className="show-card-wrapper">
        <Link
          to={`/shows/${show.slug}`}
          className="show-link"
          onMouseDown={() => setIsDragging(false)}
          onMouseMove={() => setIsDragging(true)}
          onMouseUp={() => setTimeout(() => setIsDragging(false), 0)}
          onClick={(e) => handleClick(e, show.slug)}
        >
          <Card className="featured-show-card">
          <div className="show-image-layer">
<Card.Img 
  variant="top" 
  src={show.background.includes('http')  ? show.background  : `${process.env.REACT_APP_API_BASE_URL}/img${show.background}`}
  className="show-image" 
/>
{getUserRatingForShow(show._id) ? (
  <div className="user-rating-badge"><span>{getUserRatingForShow(show._id)}</span></div>
) : (
  <div className="user-rating-badge-star">
    <img src={starIcon} alt="Rating" className="rating-star-badge" />
  </div>
)}
</div>
            <div className="show-info-layer">
              <div className="top-layer">
                <img src={show.poster.includes('http')  ? show.poster  : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`} className="show-poster" alt="Show Poster" />
                <div className="show-details">
                  <h5 className="show-title">  {show.title && show.title.length > 27 ? show.title.substring(0, 27) + "..." : show.title || ""}
                  </h5>
                  <p className="theater-name">{show.theater}</p>
                  {show.avgRating > 0 && (
                    <>
                  <div className="rating-wrapper">
                  
                    <img src={starIcon} alt="Rating" className="rating-star" />
                    <p className="average-rating">  {typeof show.avgRating === 'number' ? show.avgRating.toFixed(1) : 'N/A'}
                  </p>
                  
      
                  </div>
                  </>
                )}
                </div>
              </div>
              <p className="show-description">
{show.about && show.about.length > 120 ? show.about.substring(0, 120) + "..." : show.about || ""}
</p>
            </div>
          </Card>
        </Link>
      </div>
        ))
      )}
  </Slider>

</Col>
</Row>



 {/* Sekcja - Moje spektakle */}
{user ? (
  <>
    <Row className="my-shows my-4 mb-5 justify-content-center">
      <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center resp-pre-xl">
        <h2 className="section-title">{t('my_shows.title')}</h2>

        {/* Zakładki */}
        <div className="show-tabs show-tabs-container">
          <Button 
            variant="link" 
            onClick={() => setActiveMyShowsList('Next')} 
            className={`tab ${activeMyShowsList === 'Next' ? 'active' : ''}`}
          >
            {t('my_shows.tabs.next')}
          </Button>
          <Button 
            variant="link" 
            onClick={() => setActiveMyShowsList('Seen')} 
            className={`tab ${activeMyShowsList === 'Seen' ? 'active' : ''}`}
          >
            {t('my_shows.tabs.seen')}
          </Button>
          <Button 
            variant="link" 
            onClick={() => setActiveMyShowsList('Wish-list')} 
            className={`tab ${activeMyShowsList === 'Wish-list' ? 'active' : ''}`}
          >
            {t('my_shows.tabs.wishlist')}
          </Button>
        </div>

        {/* Lista spektakli */}
        <div className="shows-grid">
          {isLoading ? ( // Dodanie loadera w momencie ładowania danych
            <>
              {Array(9).fill().map((_, index) => (
                <SkeletonLoaderMyShows key={index} />
              ))}
            </>
          ) : activeMyShowsList === 'Wish-list' ? (
            <>
              {displayedWishListShows.map((show, index) => (
                <div key={index} className="show-card">
                  <Link to={`/shows/${show.slug}`} className="show-link">
                    <div className="show-poster">
                      <img src={show.poster.includes('http') ? show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`} alt={show.title} />
                    </div>
                  </Link>
                  <div className="show-info">
                    <Link to={`/shows/${show.slug}`} className="show-link">
                      <h5 className="show-title">{show.title}</h5>
                    </Link>

                    {/* Renderowanie przycisków na podstawie flagi `isRemoved` */}
                    {show.isRemoved ? (
  <Button 
    variant="success" 
    className="add-again-button" 
    onClick={() => addAgainToWishList(show._id)}
    disabled={loadingButtons[show._id]} // Zablokuj przycisk, gdy trwa ładowanie
  >
    {loadingButtons[show._id] ? (
      <Spinner animation="border" size="sm" /> // Wyświetl spinner, gdy trwa ładowanie
    ) : (
      t('my_shows.wishlist.add_again')
    )}
  </Button>
) : (
  <Button 
    variant="danger" 
    className="remove-button" 
    onClick={() => removeFromWishList(show._id)}
    disabled={loadingButtons[show._id]} // Zablokuj przycisk, gdy trwa ładowanie
  >
    {loadingButtons[show._id] ? (
      <Spinner animation="border" size="sm" /> // Wyświetl spinner, gdy trwa ładowanie
    ) : (
      t('my_shows.wishlist.remove')
    )}
  </Button>
)}

                  </div>
                </div>
              ))}
              {wishListShows.length > displayedWishListShows.length && (
                <Button onClick={handleShowMoreWishList}>
                  {t('my_shows.wishlist.show_more')}
                </Button>
              )}
            </>
          ) : (
            <>
              {activeMyShows.map((entry, index) => (
                <div key={index} className="show-card" onClick={() => handleShowClick(entry)}>
                  <div className="show-poster">
                    <img 
                      src={entry.show.poster.includes('http') ? entry.show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${entry.show.poster}`} 
                      alt={entry.show.title} 
                    />
                  </div>
                  
                  {entry.userRating ? (
                    <div className="user-rating-badge">
                      <span>{entry.userRating}</span>
                    </div>
                  ) : (
                    <div className="user-rating-badge-star">
                      <img src={starIcon} alt="Rating" className="rating-star-badge" />
                    </div>
                  )}
                  <div className="show-info">
                    <h5 className="show-title">{entry.show.title}</h5>
                    <div className="show-date">
                      <img src={calendarIcon} alt="Calendar" className="calendar-icon" />
                      <span>{new Date(entry.dateWatched).toLocaleDateString()}</span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        <div className="center-container">
          {activeMyShowsList !== 'Wish-list' && activeMyShows.length >= 9 && (
            <div className="all-shows-info">
              <img src={infoIcon} alt="Info" className="info-icon" />
              {t('my_shows.all-info')}
            </div>
          )}

          {activeMyShowsList !== 'Wish-list' && activeMyShows.length === 0 && (
            <div className="all-shows-info">
              <img src={infoIcon} alt="Info" className="info-icon" />
              {t('my_shows.empty-info')}
            </div>
          )}

          {activeMyShowsList === 'Wish-list' && activeMyShows.length === 0 && (
            <div className="all-shows-info">
              <img src={infoIcon} alt="Info" className="info-icon" />
              {t('my_shows.empty-wishlist-info')}
            </div>
          )}
        </div>

        <div className="my-shows-buttons mt-2">
          {/* Przycisk dodawania spektaklu do dziennika */}
          <Link to="/diary">
            <Button variant="light" className="add-show-button">
              {t('my_shows.see-all')}
            </Button>
          </Link>
          <Link to="/addToDiary">
            <Button variant="primary" className="add-show-button">
              {t('my_shows.add-show')}
            </Button>
          </Link>
        </div>
      </Col>
    </Row>
  </>
) : (
  <div></div>
)}

{/* Modal dla wybranego spektaklu */}
<ShowModal 
starIcon={starIcon}
colorCircleCalendarIcon={colorCircleCalendarIcon}
            colorCircleStarIcon={colorCircleStarIcon}
            getRatingDescription={getRatingDescription}
            renderStarRating={renderStarRating}
            successIcon={successIcon}
            t={t}
            currentLocale={currentLocale}
            selectedEntry={selectedEntry} // <-- Pass selectedEntry to ShowModal

show={selectedShow} onClose={handleCloseModal} />
{/* Sekcja - Najlepsze grane */}
<Row className="g-0 best-played my-4 mb-5 justify-content-center">
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center resp-pre-xl">
    <h2 className="section-title">{t('best-played.title')}</h2>

    {/* Zakładki lokalizacji */}
    <div className="location-links show-tabs-container">
      {predefinedLocations.map(tab => (
        <Button
          key={tab.key}
          variant="link"
          onClick={() => handleChangeLocation(tab.locations)}
          className={
            currentLocation.length === tab.locations.length &&
            tab.locations.every(location => currentLocation.includes(location))
              ? 'active'
              : ''
          }
        >
          {tab.label}
        </Button>
      ))}
    </div>
  </Col>

  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="best-played-col resp-pre-xl">
    {/* Wyświetlanie posortowanych spektakli lub SkeletonLoader */}
    {isLoading ? (
      <>
        {Array(6).fill().map((_, index) => (
          <SkeletonLoaderBest key={index} />
        ))}
      </>
    ) : (
      showsWithDynamicRank.map((show, index) => (
        <div key={index} className="show-card">
          {/* Plakat i ocena */}
          <div className="show-poster">
            <Link to={`/shows/${show.slug}`} className="show-link">
              <img 
                src={show.poster.includes('http') ? show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`} 
                alt={show.title} 
                className="poster-img" 
              />
            </Link>
            {getUserRatingForShow(show._id) ? (
              <div className="user-rating-badge">
                <span>{getUserRatingForShow(show._id)}</span>
              </div>
            ) : (
              <div className="user-rating-badge-star">
                <img src={starIcon} alt="Rating" className="rating-star-badge" />
              </div>
            )}
          </div>

          {/* Info o spektaklu */}
          <div className="show-info">
            <div className="ranking">
              <img src={rankIcon} alt="Ranking" className="rank-icon" />
              <span>{show.dynamicRank}.</span>
            </div>
            <div className="show-info-basic">
              <Link to={`/shows/${show.slug}`} className="show-link">
                <h5 className="show-title">{show.title}</h5>
              </Link>
              <div className="theater-info">{show.theater}</div>
              <div className="location">{show.location}</div>
            </div>
            <div className="rating">
              <img src={starIcon} alt="Rating" className="rating-star" />
              <span>{typeof show.avgRating === 'number' ? show.avgRating.toFixed(1) : 'N/A'}</span>
            </div>
            <div className="tags">
              {show.tags.map((tag, tagIndex) => (
                <span key={tagIndex} className="tag">{tag}</span>
              ))}
            </div>
          </div>
        </div>
      ))
    )}
  </Col>

  {/* Przycisk */}
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center center-container justify-content-center resp-pre-xl mt-2">
    <Button 
      variant="primary" 
      className="see-rank-button" 
      onClick={() => handleNavigation('/best-played')}
    >
      {t('best-played.see-full')}
    </Button>
  </Col>
</Row>


           {/* Sekcja - Premiery sezonu */}
     <Row className="g-0 my-4 mb-5 justify-content-center this-season">
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center resp-pre-xl px12">
  <h2 className="section-title mb-1">{t('this_season.title')}</h2>
  </Col>
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl px12"> 
    <Slider className="mb-0" {...settings}>
    {thisSeasonShows.map((show, index) => (
        <div key={index} className="show-card-wrapper">
          <Link
            to={`/shows/${show.slug}`}
            className="show-link"
            onMouseDown={() => setIsDragging(false)}
            onMouseMove={() => setIsDragging(true)}
            onMouseUp={() => setTimeout(() => setIsDragging(false), 0)}
            onClick={(e) => handleClick(e, show.slug)}
          >
            <Card className="featured-show-card">
            <div className="show-image-layer">
  <Card.Img 
    variant="top" 
    src={show.poster.includes('http')  ? show.poster  : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`}
    className="show-image" 
  />
{getUserRatingForShow(show._id) && getUserRatingForShow(show._id) > 0 ? (
  <div className="user-rating-badge2"><span>{getUserRatingForShow(show._id)}</span></div>
) : (
  <div className="user-rating-badge-star2">
    <img src={starIcon} alt="Rating" className="rating-star-badge2" />
  </div>
)}

</div>
              <div className="show-info-layer">
                <div className="show-info">
                  <h5 className="show-title">{show.title}</h5>
                  <p class="theater-name">{show.theater && show.theater.length > 120 ? show.theater.substring(0, 120) + "..." : show.theater || ""}</p>
                  <div className="show-date">
                    <img src={calendarIcon} alt="Calendar" className="calendar-icon" />
                    <span>{formatDate(show.premiere)}</span>
                  </div>
                </div>
              </div>
            </Card>
          </Link>
        </div>
      ))}
    </Slider>
  </Col>
</Row>


      {/* Sekcja - Szybkie linki */}
      <Row className="g-0 mb-5 my-4 quick-links justify-content-center">
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center resp-pre-xl">
    <h2 className="section-title">{t('quick-links.title')}</h2>
    <div className="my-shows-buttons">
      {/* Te przyciski przewiną stronę na górę i przeładują */}
      <Button variant="light" className="quick-button" onClick={() => handleNavigation('/diary')}>
        <img src={bigDiaryIcon} alt="Diary" className="quick-icon" />
        {t('quick-links.your-shows')}
      </Button>
      <Button variant="light" className="quick-button" onClick={() => handleNavigation('/stats-rank')}>
        <img src={heartIcon} alt="Best of currently played" className="quick-icon" />
        {t('quick-links.stats-rank')}
        </Button>
      <Button variant="light" className="quick-button" onClick={() => handleNavigation('/best-played')}>
        <img src={bigRankIcon} alt="Best of currently played" className="quick-icon" />
        {t('quick-links.best-currently')}
      </Button>
      <Button variant="light" className="quick-button" onClick={() => handleNavigation('/best-all-time')}>
        <img src={bigRankAllIcon} alt="Best of all time" className="quick-icon" />
        {t('quick-links.best-alltime')}
      </Button>


    </div>
  </Col>
</Row>


    </Container>

  
  );
}

export default Homepage;

