import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Button } from 'react-bootstrap';

import ShowModal from './ShowModal';


const ShowPage = ({ 
  rankIcon, 
  starIcon, 
  calendarIcon,
  getRatingDescription,
  renderStarRating,
  colorCircleCalendarIcon,
  colorCircleStarIcon,
  user,
  userIcon,
  messageIcon,
  fileIcon,
  musicIcon,
  clockIcon,
  helpIcon,
  t,
  currentLocale,
  formatDate,
  successIcon
}) => {
  const { slug } = useParams(); 

  const [show, setShow] = useState(null); 
  const [userDiaryEntries, setUserDiaryEntries] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const [isInWishList, setIsInWishList] = useState(false); 
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedShow, setSelectedShow] = useState(null);
  const [selectedEntry, setSelectedEntry] = useState(null); // <-- Add this line


  const navigate = useNavigate();




useEffect(() => {
  const fetchShow = async () => {
    try {
      const showResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/shows/${slug}`);
      setShow(showResponse.data || null);

      const token = localStorage.getItem('token');
      if (token) {
        const diaryResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/diary`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserDiaryEntries(diaryResponse.data.filter(entry => entry.show?._id === showResponse.data?._id));

        const userResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const isInList = userResponse.data.wishList.includes(showResponse.data?._id);
        setIsInWishList(isInList);
      }
    } catch (error) {
      console.error('Error fetching the show or diary data:', error);
      setShow(null);
    } finally {
      setLoading(false);
    }
  };

  fetchShow();
}, [slug]);



  // Funkcje obsługujące dodawanie i usuwanie spektaklu z listy życzeń
  const handleAddToWishList = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/wishlist/add`, 
        { showId: show?._id }, 
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setIsInWishList(true);
    } catch (err) {
      console.error('Error adding to wish list:', err.response?.data || err);
    }
  };

  const handleRemoveFromWishList = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/wishlist/remove`, 
        { showId: show?._id }, 
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setIsInWishList(false);
    } catch (err) {
      console.error('Error removing from wish list:', err);
    }
  };

const getUserRatingForShow = () => {
  if (userDiaryEntries.length === 0) return null;
  const ratings = userDiaryEntries
  .map(entry => entry.userRating)
  .filter(rating => rating !== null && rating !== 0); // Dodano warunek, aby wykluczyć oceny 0  if (ratings.length === 0) return null;
  const totalRating = ratings.reduce((acc, rating) => acc + rating, 0);
  return (totalRating / ratings.length).toFixed(1);
};

  if (loading) {
    return <div className="loader-container"><div className="loader"></div></div>    ;
  }

  if (!show) {
    return <div>Show not found</div>;
  }

  // Sprawdzenie, czy show jest zweryfikowany
  if (!show.isVerified) {
    return (
      <div className="container my-5 px-5 text-center">
        <h2 className="text-center pb-4">{t('showpage.not-verified_title')}</h2>
        <p>{t('showpage.not-verified_description')}</p>
      </div>
    );
  }

  const userRating = getUserRatingForShow();
  const ratingDescription = userRating ? getRatingDescription(userRating) : null;


  const maxLength = 440;
  

  const renderParagraphs = (text) => {
    return (
      <p
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  };
  
  
  
  

  const shortDescription = show.about ? show.about.slice(0, maxLength) : '';

  const handleShowClick = (entry) => {
    setSelectedShow(entry);
    setSelectedEntry(entry); // <-- Set selectedEntry instead of selectedShow

  };
  
  const handleCloseModal = () => {
    setSelectedShow(null);
    setSelectedEntry(null); // Reset selectedEntry when modal closes

  };
  

  const pastShowsCount = userDiaryEntries.filter(entry => new Date(entry.dateWatched) < new Date()).length;
  const upcomingShowsCount = userDiaryEntries.filter(entry => new Date(entry.dateWatched) >= new Date()).length;

  const handleAddToDiaryClick = () => {
    if (show && show.slug) {
      navigate(`/addToDiary?slug=${show.slug}`);
    } else {
      console.error('Slug is missing or invalid:', show);
    }
  };

  return (
    <Container fluid className="show-page px-3 justify-content-center">
      {/* Nagłówek z tłem */}
      <Row className="no-margin-row">
    <Col xs={12} className="p-0">
      <div className="master-show-header">
        <div className="background-color-overlay"></div> {/* Overlay dla tła */}
        <div className="image-wrapper">
        {show.background && (
  <img 
    src={show.background.includes('http') ? show.background : `${process.env.REACT_APP_API_BASE_URL}/img${show.background}`}
    alt={show.title || 'No title'} 
    className="img-fluid master-show-background" 
  />
)}
          <div className="image-gradient"></div> {/* Gradient overlay */}
        </div>
      </div>
    </Col>
  </Row>

{/* Karta spektaklu z podstawowymi informacjami */}
<Row className="master-show-card mx-0 justify-content-center col-lg-11 col-xl-10 resp-pre-xl">
  <Col className="d-flex">
    <div className="master-show-poster">
    <img src={show.poster && show.poster.includes('http')  ? show.poster  : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster || ''}`} className="poster-img" alt={show.title || 'No title'} />    </div>
    <div className="show-info flex-grow-1">
      {show.globalRank > 0 && (
        <div className="ranking">
          <img src={rankIcon} alt="Rating" className="rank-icon" />
          <span>#{show.globalRank || 'N/A'} {t('showpage.rank-global')}</span><span>&nbsp;·&nbsp;</span>
          <span>#{show.nationalRank || 'N/A'} {show.country}</span><span>&nbsp;·&nbsp;</span>
          <span>#{show.localRank || 'N/A'} {show.location}</span>
        </div>
      )}

      <div className="show-info-basic">
        <h5 className="show-title">{show.title || 'No title'}</h5>

        {/* Sekcja teatru - wyświetlana tylko, gdy istnieje nazwa teatru */}
        {show.theater && (
          <div className="theater-info">{show.theater}</div>
        )}

        {/* Sekcja lokalizacji - wyświetlana tylko, gdy istnieje lokacja */}
        {show.location && (
          <div className="location">{show.location}</div>
        )}
      </div>

      {show.avgRating > 0 && (
        <div className="rating">
          <img src={starIcon} alt="Rating" className="rating-star" />
          <span>{(show.avgRating || 0).toFixed(1)}</span>
        </div>
      )}

      {/* Sekcja tagów - wyświetlana tylko, gdy istnieją tagi */}
      {show.tags && show.tags.length > 0 && (
        <div className="tags">
          {show.tags.map((tag, index) => (
            <span key={index} className="tag">{tag}</span>
          ))}
        </div>
      )}
    </div>
  </Col>
</Row>

      {/* Sekcja z przyciskami */}
      <Row className="no-relative-position showpage-buttons mb-2 g-0 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
          {/* Link do zakupu biletów */}
          {show.ticketUrl && (
            <a href={show.ticketUrl} target="_blank" rel="noopener noreferrer">
              <Button variant="primary" className="get-tickets mb-3">{t('showpage.get-tickets-button')}</Button>
            </a>
          )}
        </Col>
      </Row>
    
      {user && (
<>
      {/* Sekcja z oceną użytkownika */}
      <Row className="my-rating mt-4 g-0 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
          <h2 className="section-title">{t('showpage.my_rating.title')}</h2>

          <div className="rating-top-section d-flex align-items-center justify-content-between">
            {userDiaryEntries.length > 0 ? (
              <div className="rating-top-section-text">
                <h3 className="section-subtitle">{t('showpage.my_rating.subtitle-summary')}</h3>
                <p className="rating-summary-p">
                  
  {t('showpage.my_rating.your-average-rating')}:&nbsp; 
  <strong>
    {userRating < 1 ? t('modals.add_show_modal.no_rating') : ratingDescription || t('modals.add_show_modal.nothing')} ({userRating < 1 ? t('modals.add_show_modal.nothing') : userRating || 'N/A'})
  </strong>
</p>
                <p className="rating-summary-p">
                {t('showpage.my_rating.you-have-seen')}: <strong>
                    {pastShowsCount} {pastShowsCount > 1 ? t('showpage.my_rating.seen-more') : t('showpage.my_rating.seen-1')}
                    {upcomingShowsCount > 0 && ` (${upcomingShowsCount} ${t('showpage.my_rating.upcoming')})`}
                  </strong>
                </p>
              </div>
            ) : (
              <div className="rating-top-section-none">
                <p>{t('showpage.my_rating.you-have-not-seen')}</p>
              </div>
            )}
          
          <div>
            <img 
              src={user.avatarUrl.includes('http') ? user.avatarUrl : `${process.env.REACT_APP_API_BASE_URL}/img${user.avatarUrl}`} 
              alt="User Avatar" 
              className="user-avatar" 
            />
          </div>
        </div>
          <div>
          {userDiaryEntries.length > 0 ? (
              renderStarRating(userRating)
            ) : (
              <span></span>
            )}
          </div>
        </Col>
      </Row>

      {/* Sekcja z obejrzanymi spektaklami */}
      {userDiaryEntries.length > 0 && (
        <Row className="shows-seen mt-4 mb-0 justify-content-center">
          <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
            <h3 className="section-subtitle">{t('showpage.my_rating.subtitle-showsseen')}</h3>
            <div className="shows-grid">
              {userDiaryEntries.sort((a, b) => new Date(b.dateWatched) - new Date(a.dateWatched)).map((entry, index) => (
                <div 
                  key={index} 
                  className="show-card" 
                  onClick={() => handleShowClick(entry)}
                >
                            <div className="show-poster">

                  <img src={entry.show.poster.includes('http')  ? entry.show.poster  : `${process.env.REACT_APP_API_BASE_URL}/img${entry.show.poster}`}  alt={entry.show.title}  />

                  </div>
                  {entry.userRating ? (
                    <div className="user-rating-badge">
                      <span>{entry.userRating}</span>
                    </div>
                  ) : (
                    <div className="user-rating-badge-star">
                      <img src={starIcon} alt="Rating" className="rating-star-badge" />
                    </div>
                  )}
                  
                  <div className="show-date">
  <p><img src={calendarIcon} alt="Calendar" className="calendar-icon" /> {new Date(entry.dateWatched).toLocaleDateString()}</p>
  {new Date(entry.dateWatched) > new Date() ? <p className="upcoming">{t('showpage.my_rating.upcoming')}</p> : ''}
</div>

                </div>

              ))}
            </div>
          </Col>
        </Row>
      )}

    

<Row className="mb-5 showpage-buttons showsseen-buttons g-0 justify-content-center">
<div className='center-container'>

{userDiaryEntries.length > 0 ? (
              <Button variant="light" onClick={handleAddToDiaryClick} className="mt-3">{t('showpage.my_rating.add-show-button')}</Button>
) : (
  <Button variant="primary" onClick={handleAddToDiaryClick} className="mt-3">{t('showpage.my_rating.add-first-show-button')}</Button>

)}
                      {/* Przycisk dodawania/usuwania z listy życzeń */}
          {isInWishList ? (
            <Button variant="danger" className="remove-button" onClick={handleRemoveFromWishList}>
              {t('showpage.my_rating.remove-wish-button')}
            </Button>
          ) : (
            <Button variant="light" className="" onClick={handleAddToWishList}>
              {t('showpage.my_rating.add-wish-button')}
            </Button>
          )}
          </div>
</Row>
</>
)}

{show.about && (
  <>
 {/* Sekcja opisująca spektakl */}
 <Row className="about mt-4 justify-content-center">
      <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
        <h2 className="section-title">{t('showpage.about.title-about')}</h2>
        <div className="about-text">
            {isExpanded ? renderParagraphs(show.about) : renderParagraphs(shortDescription)}
          </div>
<div className="center-container">
          {!isExpanded && show.about && show.about.length > maxLength && (
            <Button variant="light" className="add-show-button mt-3" onClick={() => setIsExpanded(true)}>
            {t('showpage.about.read-more-button')}
          </Button>          )}

        {isExpanded && (
          <Button variant="light" className="add-show-button mt-3" onClick={() => setIsExpanded(false)}>
            {t('showpage.about.less-info-button')}
        </Button>
        )}
        </div>
      </Col>
    </Row>
    </>
)}
{/* Sekcja z dodatkowymi informacjami */}
{(show.duration || show.direction || show.author || show.musicAuthor || show.translation || show.premiere) && (
  <Row className="information mt-4 justify-content-center">
    <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
      <h2 className="section-title">{t('showpage.about.title-info')}</h2>

      <div className="info-table">
        {show.duration && (
          <div className="info-row">
            <div className="info-icon">
              <img src={clockIcon} alt="Icon" />
            </div>
            <div className="info-name">{t('showpage.about.info-duration')}</div>
            <div className="info-content">{show.duration} min</div>
          </div>
        )}

        {show.direction && (
          <div className="info-row">
            <div className="info-icon">
              <img src={userIcon} alt="Icon" />
            </div>
            <div className="info-name">{t('showpage.about.info-direction')}</div>
            <div className="info-content">{show.direction}</div>
          </div>
        )}

        {show.author && (
          <div className="info-row">
            <div className="info-icon">
              <img src={fileIcon} alt="Icon" />
            </div>
            <div className="info-name">{t('showpage.about.info-author')}</div>
            <div className="info-content">{show.author}</div>
          </div>
        )}

        {show.musicAuthor && (
          <div className="info-row">
            <div className="info-icon">
              <img src={musicIcon} alt="Icon" />
            </div>
            <div className="info-name">{t('showpage.about.info-music')}</div>
            <div className="info-content">{show.musicAuthor}</div>
          </div>
        )}

        {show.translation && (
          <div className="info-row">
            <div className="info-icon">
              <img src={messageIcon} alt="Icon" />
            </div>
            <div className="info-name">{t('showpage.about.info-translation')}</div>
            <div className="info-content">{show.translation}</div>
          </div>
        )}

        {show.premiere && (
          <div className="info-row">
            <div className="info-icon">
              <img src={calendarIcon} alt="Icon" />
            </div>
            <div className="info-name">{t('showpage.about.info-premiere')}</div>
            <div className="info-content">{formatDate(show.premiere)}</div>
          </div>
        )}
      </div>
    </Col>
  </Row>
)}


      {/* Sekcja obsady */}

      <Row className="cast-section-row mt-4 justify-content-center">
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
    {show.cast && show.cast.length > 0 && (
      <>
        <h2 className="section-title">{t('showpage.about.title-cast')}</h2>
        <div className="cast-section">
        {show.cast.sort((a, b) => a.order - b.order).map((castMember, index) => (
          <div className="cast-member" key={index}>
            <img 
              src={`${process.env.REACT_APP_API_BASE_URL}/img${castMember.actor.photoUrl}`}
              alt={`${castMember.actor.firstName} ${castMember.actor.lastName}`} 
              className="cast-photo"
            />
            <p className="cast-name">{castMember.actor.firstName} {castMember.actor.lastName}</p>
          </div>
        ))}
      </div>
      </>
    )}
  </Col>
</Row>

<ShowModal
  starIcon={starIcon}
  colorCircleCalendarIcon={colorCircleCalendarIcon}
  colorCircleStarIcon={colorCircleCalendarIcon}
  getRatingDescription={getRatingDescription}
  renderStarRating={renderStarRating}
  show={selectedShow}
  onClose={handleCloseModal}
  t={t}
  currentLocale={currentLocale}
  successIcon={successIcon}
  selectedEntry={selectedEntry} // <-- Pass selectedEntry to ShowModal

/>

    </Container>
  );
};

export default ShowPage;