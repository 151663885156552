import React, { useState, useEffect } from 'react';
import { Modal, Button, FormControl, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function AddShowModal({ 
    show, onClose, colorCircleCalendarIcon, colorCircleStarIcon, starIcon, t, isLoading, isSuccess, setIsLoading, setIsSuccess,
    getRatingDescription, handleAddToDiary, successIcon, modalIsOpen, navigate, confirmationMsg, setConfirmationMsg, currentLocale
}) {

  const [userRating, setUserRating] = useState(0);
  const [tempRating, setTempRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(null);
  const [ratingDescription, setRatingDescription] = useState('');
  const [isDateModalOpen, setIsDateModalOpen] = useState(false); 
  const [dateWatched, setDateWatched] = useState(new Date());
  const [pendingDateWatched, setPendingDateWatched] = useState(new Date());
  const [manualYear, setManualYear] = useState(new Date().getFullYear()); // Rok wprowadzony ręcznie
  


  useEffect(() => {
    if (show) {
      setUserRating(0);
      setTempRating(0);
      setRatingDescription(getRatingDescription(0));
  
      const today = new Date();
      setDateWatched(today);
      setPendingDateWatched(today);
    }
  }, [show, getRatingDescription]);
  

  const handleRatingChange = (newRating) => {
    setTempRating(newRating);
    setRatingDescription(getRatingDescription(newRating));
  };

  const handleMouseEnter = (index) => {
    setHoverRating(index);
  };

  const handleMouseLeave = () => {
    setHoverRating(null);
  };

  const renderStarRating = (rating) => {
    const stars = [];
    for (let i = 1; i <= 10; i++) {
      stars.push(
        <span
          key={i}
          onClick={() => handleRatingChange(i)}
          onMouseEnter={() => handleMouseEnter(i)}
          onMouseLeave={handleMouseLeave}
          className={'star-icon'}
          style={{ color: i <= (hoverRating ?? tempRating) ? '#FFC319' : '#ccc', cursor: 'pointer' }}
        >
          ★
        </span>
      );
    }
    return stars;
  };

  const handleOpenDateModal = () => {
    setIsDateModalOpen(true);
  };

  const handleCloseDateModal = () => {
    setIsDateModalOpen(false);
  };

  const handleDateChange = (date) => {
    setPendingDateWatched(date);
    setManualYear(date.getFullYear()); // Aktualizacja pola z rokiem przy wyborze daty z kalendarza

  };

  const handleYearChange = (e) => {
    const year = parseInt(e.target.value, 10);
    if (!isNaN(year)) {
      setManualYear(year);
      const updatedDate = new Date(pendingDateWatched);
      updatedDate.setFullYear(year); // Zmieniamy rok w aktualnie wybranej dacie
      setPendingDateWatched(updatedDate); // Aktualizujemy datę w kalendarzu
    }
  };

  const handleSaveDateChange = () => {
    setDateWatched(pendingDateWatched);
    setIsDateModalOpen(false);
  };

  const handleSaveAndClose = async () => {
    setIsLoading(true);  // Natychmiastowe włączenie loadera
    setIsSuccess(false); // Resetowanie sukcesu, aby ukryć wcześniejsze komunikaty
    const ratingToSave = tempRating === 0 ? null : tempRating;
    const dateToSave = dateWatched;
    
    await handleAddToDiary(dateToSave, ratingToSave);

    // Po zakończeniu zapisu ustawiamy komunikat potwierdzający i sukces
    setConfirmationMsg(t('modals.add_show_modal.confirmation_message'));
    setIsLoading(false);  // Wyłączenie loadera po zapisaniu
    setIsSuccess(true);   // Ustawienie statusu sukcesu
};

  const handleModalClose = () => {
    setTempRating(userRating);
    setHoverRating(null);
    onClose();
    setConfirmationMsg('');
  };

  if (!show) return null;

  

  return (
<>
  <Modal show={!!show} onHide={handleModalClose} centered backdrop="true">
    <Modal.Header closeButton className="modal-header"></Modal.Header>
    <Modal.Body>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : isSuccess ? (
        <div className="confirmation-message-modal-rem">
        <div className="confirmation-message">
          <img src={successIcon} className="success-icon" alt="Success" />
          <p>{confirmationMsg || t('modals.add_show_modal.success_message')}</p>

          <Button variant="primary" onClick={() => navigate('/diary')}>{t('modals.add_show_modal.close_button')}</Button>

        </div>
        </div>
      ) : (
        <div className="modal-section">
          <div className="show-card">
            <div className="show-poster">
            {show?.isVerified ? (
              <Link to={`/shows/${show?.slug}`} className="show-link">
                <img 
                  src={
                    show?.poster.includes('http')
                      ? show?.poster
                      : `${process.env.REACT_APP_API_BASE_URL}/img${show?.poster}`
                  } 
                  alt={show?.title} 
                  className="modal-poster" 
                />
              </Link>
            ) : (
              <img 
                src={
                  show?.poster.includes('http')
                    ? show?.poster
                    : `${process.env.REACT_APP_API_BASE_URL}/img${show?.poster}`
                } 
                alt={show?.title} 
                className="modal-poster" 
              />
            )}
              
            </div>
          </div>
          <h5 className="show-title">{show?.title}</h5>
          <div className="modal-subsection">
            <div className="modal-small-section">
              <div className="modal-small-section-top">
                <div className="icon-section">
                  <img src={colorCircleCalendarIcon} alt="Calendar Icon" />
                </div>
                <div className="content">
                  <h6><strong>{t('modals.add_show_modal.date_of_show')}</strong></h6>
                  <p className="sub">{dateWatched ? dateWatched.toLocaleDateString() : t('modals.add_show_modal.no_date')}</p>
                </div>
              </div>
              <div className="modal-small-section-bottom">
                <Button variant="light" className="btn-small" onClick={handleOpenDateModal}>
                  {t('modals.add_show_modal.change_date_button')}
                </Button>
              </div>
            </div>
            <div className="modal-small-section">
              <div className="modal-small-section-top">
                <div className="icon-section">
                  <img src={colorCircleStarIcon} alt="Star Icon" />
                </div>
                <div className="content">
                  <strong>{t('modals.add_show_modal.rating_label')}</strong>
                  <p className="rating-summary-p">
                    {t('modals.add_show_modal.your_rating')}:&nbsp;
                    <strong>{tempRating ? `${ratingDescription} (${tempRating})` : t('modals.add_show_modal.no_rating')}</strong>
                  </p>
                </div>
              </div>
              <div className="modal-small-section-bottom">
                <div className="star-rating">
                  {renderStarRating(tempRating)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal.Body>
    {!isLoading && !isSuccess && (
      <Modal.Footer className="modal-footer">
        <Button variant="primary" onClick={handleSaveAndClose}>{t('modals.add_show_modal.save_button')}</Button>
        <Button variant="light" onClick={handleModalClose}>{t('modals.add_show_modal.cancel_button')}</Button>
      </Modal.Footer>
    )}
  </Modal>

  {/* Date modal */}
  <Modal show={isDateModalOpen} onHide={handleCloseDateModal} className="modal-calendar-container" centered>
    <Modal.Header closeButton />
    <Modal.Body className="custom-datepicker">
      <div className="modal-calendar">
        <InputGroup className="mb-3">
          <InputGroup.Text>{t('modals.show_modal.year_label')}</InputGroup.Text>
          <FormControl
            type="number"
            value={manualYear}
            onChange={handleYearChange}
            aria-label="Year"
          />
        </InputGroup>
        <DatePicker 
          selected={pendingDateWatched}
          onChange={handleDateChange}
          inline
          calendarStartDay={1}
          locale={currentLocale}
        />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={handleSaveDateChange}>{t('modals.add_show_modal.save_button')}</Button>
      <Button variant="light" onClick={handleCloseDateModal}>{t('modals.add_show_modal.cancel_button')}</Button>
    </Modal.Footer>
  </Modal>
</>
  );
}

export default AddShowModal;
